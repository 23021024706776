/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body { height: 100%; }
body { 
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #041C32;
  color: #b1b0b0;
}
